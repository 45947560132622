@import "../../themes/generated/variables.base.scss";

.footer {
  display: block;
  text-align: right;
  color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  /*border-top: 1px solid rgba(0, 0, 0, 0.1);*/
  padding-top: 10px;
  padding-bottom: 10px;
}
