/*#diary .dx-scheduler-cell-sizes-vertical {
  height: 36px;
}

.app .content-block {
  margin-top: 0 !important;
}*/

#diary {
  height: 60vh !important;
}

.diary-main {
  overflow-y: auto;
  height: auto;
  padding: 10px !important;
}

.diary-con {
  overflow-y: auto;
}

.dx-scheduler-appointment {
  background-color: #03a9f4;
}

.bookings-preview {
  min-height: 2vh;
  height: inherit;
  width: 100%;
  padding: 0;
}

.bookings-preview-value {
  white-space: normal;
  margin-left: 2px;
}

.bookings-day-preview-value {
  float: left;
  font-size: 1rem;
  width: auto;
  margin-left: 0.2px;
}

.dx-scheduler-all-day-appointment {
  min-height: 35px !important;
  transform: translate(0px, 10px) !important;
  text-align: center;
}

.day {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  font-weight: 400;
}

.week {
  font-size: 0.7rem;
}

.workweek {
  font-size: 0.8rem;
}

.bookings-week-preview-value {
  white-space: normal;
  margin-left: 2px;
}

.bookings-month-preview-value-reason {
  display: none;
}

.bookings-month-preview-value {
  float: left;
  text-align: center;
  margin-right: 5px;
}

.bookings-small-preview-value {
  width: auto;
  font-size: 0.8rem;
}

.bookings-small-hide-value {
  display: none;
}

.indicator-container {
  float: left;
  height: inherit;
  width: auto;
  margin-right: 0%;
}

.indicator-container-day {
  margin-right: 2.5%;
}

.indicator {
  opacity: 0;
  width: 10px;
  height: inherit;
  //border-radius: 2px;
  background: #56ca85;
  //box-shadow: 0px 0px 0px 1px #fff;
}

.indicator-day {
  width: 10px;
}

.indicator-week {
  margin-right: 0%;
}

.indicator-work-week {
  margin-right: 2.5%;
}

.Red {
  background: #ee4747;
}

.Purple {
  background: #9c669c;
}

.Orange {
  background: #e9a852;
  color: #000;
}

.Pink {
  background: #f09ae4;
  color: #000;
}

.Turquoise {
  background: #6ce4ca;
  color: #000;
}

.Yellow {
  background: #e4dc6c;
  color: #000;
}

.iconRed {
  color: #ee4747 !important;
}

.iconPurple {
  color: #9c669c !important;
}

.iconOrange {
  color: #e9a852 !important;
}

.iconPink {
  color: #f09ae4 !important;
}

.iconTurquoise {
  color: #6ce4ca !important;
}

.iconYellow {
  color: #e4dc6c !important;
}

.iconWhite {
  color: #ffffff !important;
}

.iconDisable {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
}

.keySquares {
  width: 15px;
  height: 15px;
  padding: 1px;
  border: 1px solid #000;
  text-align: left;
  margin-right: 5px;
}

.keyCol {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.keyRow {
  display: flex;
  flex-direction: row;
}

.keyCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.iconKeyRed {
  background: #ee4747 !important;
}

.iconKeyPurple {
  background: #9c669c !important;
}

.iconKeyOrange {
  background: #e9a852 !important;
}

.iconKeyPink {
  background: #f09ae4 !important;
}

.iconKeyTurquoise {
  background: #6ce4ca !important;
}

.iconKeyYellow {
  background: #e4dc6c !important;
}

.iconKeyWhite {
  background: #ffffff !important;
  border: none;
}

.iconKeyAdded {
  background: #56ca85 !important;
}

.iconKeyDefault {
  background: #03a9f4 !important;
}

.addedToPas {
  opacity: 100;
  background: #56ca85 !important;
}

.addedToPasColor {
  color: #56ca85 !important;
}

.booking-info-container {
  display: flex;
  margin: 0 auto;
  text-align: left;
  padding: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
}

.booking-tooltip .booking-info {
  background: #f7f6f6;
  box-shadow: 0px 0px 6px #d9d9d9;
  padding: 10px;
  width: 50%;
  text-align: left;
}

.booking-header-con {
  display: flex;
  margin: 0 auto;
  text-align: left;
  padding: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-around;
}

.booking-tooltip img {
  height: 80px;
  margin-bottom: 10px;
}

.booking-tooltip .booking-title {
  font-size: 1.5em;
  line-height: 40px;
}

.long-title h3 {
  font-family: "Segoe UI Light", "Helvetica Neue Light", "Segoe UI",
    "Helvetica Neue", "Trebuchet MS", Verdana;
  font-weight: 200;
  font-size: 28px;
  text-align: left;
  margin-bottom: 20px;
}

.booking-sub-con {
  display: flex;
  width: 100%;
}

.booking-sub-title,
.booking-header-icon {
  flex: 1 1;
  margin: 0 auto;
  font-weight: 300;
}

.booking-sub-content,
.booking-header {
  flex: 4 1;
  font-weight: 600;
  word-wrap: break-word;
  hyphens: auto;
  padding: 1px;
}

.booking-header {
  flex: auto;
  font-size: 1.1rem;
  font-weight: 700;
  width: 100%;
}

.icon-con {
  display: flex;
  width: 100%;

  p {
    margin: 0 auto;
    text-align: left;
    width: 100%;
    flex: 1;
    padding: 8px;
    font-weight: 600;
  }

  .dx-icon-isnotblank {
    flex: 0;
    font-size: 2.2rem;
    color: #03a9f4;
    z-index: 999;
  }
}

.icon {
  display: flex;
  width: 100%;
}

.diary-add {
  display: flex;
  /*flex-direction: column;*/
  height: auto;
  width: 100%;
  /*align-items: flex-end;*/

  .dx-button {
    flex: 1;
    max-width: 1vw;
    margin: auto;
    background-color: #03a9f4;
    color: #fff;
    box-shadow: 0 1px 3px rgb(0 0 0 / 24%) !important;
    z-index: 999;
  }

  .key-left {
    flex: 1;
    margin: auto;
    width: 50%;

    ul {
      list-style: none;
      padding-left: 1px;
      li {
        padding-left: 5px;
        i {
          font-size: 1.2rem;
        }
        .added {
          color: #56ca85;
        }
        .default {
          color: #03a9f4;
        }
      }
    }
  }

  .key-right {
    flex: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: row;
  }
}

.idleWarningCon {
  width: 80%;
}

.idleWarning {
  background: #ffa500;
  color: #fff;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  padding: 1%;
  text-shadow: 0px 1px 1px #000;
}

.loading {
  display: flex;
  width: 100%;
  height: 50vh;
  padding: 5%;
  margin: auto;
  z-index: 999;

  .loader-icon {
    display: flex;
    margin: auto;
  }
}

.title-con {
  display: flex;

  .title-left {
    flex: 6;
    width: 50%;
  }
  .title-right {
    flex: 1;
    margin: auto;
    width: 50%;
    text-align: right;
  }
}

.bottom-con {
  display: flex;
  height: 0;
  width: 100%;

  .add-app {
    flex: 6;
    margin: auto;
    width: 50%;
  }
}

.dx-scheduler-appointment {
  background-color: #03a9f4;

  .dx-scheduler-appointment-content {
    padding: 4px 2px;
  }
}

@-moz-document url-prefix() {
  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
    position: relative;
  }

  .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .disable-date {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.disable-date,
.dinner {
  height: 100%;
  width: 100%;
}

.disable-date {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #9b6467;
}

.dx-scheduler-header-panel-cell .disable-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dinner {
  background: rgba(255, 193, 7, 0.2);
}

.dx-scheduler-time-panel-cell .dinner {
  color: rgba(255, 193, 7);
  font-weight: 400;
  background: transparent;
}

.dx-draggable {
  cursor: auto;
}

td.dx-scheduler-time-panel-cell .dinner .cafe {
  height: 200%;
  width: 100%;
  left: 50%;
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M20 3H4v10c0 2.21 1.79 4 4 4h6c2.21 0 4-1.79 4-4v-3h2c1.11 0 2-.9 2-2V5c0-1.11-.89-2-2-2zm0 5h-2V5h2v3zM4 19h16v2H4z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-position-y: 50%;
  -webkit-mask-position-x: 100%;
  margin-top: -4px;
  background-color: #ffc107;
}

.dx-scheduler-date-table-cell {
  padding: 0;
  opacity: 1;
}

.dx-scrollview-content {
  max-height: 70vh;
  overflow-y: auto;
}

.disable-date,
.dinner {
  height: 100%;
  width: 100%;
}

.disable-date {
  background-image: repeating-linear-gradient(
    135deg,
    rgba(244, 67, 54, 0.1),
    rgba(244, 67, 54, 0.1) 4px,
    transparent 4px,
    transparent 9px
  );
  color: #9b6467;
}

@media all and (-ms-high-contrast: none) {
  td.dx-scheduler-time-panel-cell .dinner .cafe {
    background-color: transparent;
  }
}

@media print {
  @page {
    size: landscape;
  }

  #diary {
    height: initial !important;
    overflow: initial !important;
  }
}

.dx-toast-info {
  background-color: #03a9f4 !important;
}
.dx-toast-warning {
  background-color: #ffc107 !important;
}
.dx-toast-error {
  background-color: #f44336 !important;
}
.dx-toast-success {
  background-color: #8bc34a !important;
}
