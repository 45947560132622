.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }

  ul,
  p {
    text-align: center;
  }

  .loading-home {
    display: flex;
    width: 90%;
    height: 50%;
    padding: 5%;
    margin: auto;
    z-index: 999;
  }

  .loader-icon-home {
    display: flex;
    margin: auto;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 19px;
}

.react-logo {
  width: 184px;
  height: 68px;
}

.plus {
  margin: 23px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}

.info {
  width: 50%;
  margin: auto;
  font-weight: 400;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 3px rgb(0 0 0 / 25%);
  background: #ffc107;

  p {
    width: auto;
    margin: auto;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.87);
  }
}

p {
  width: 35rem;
  margin: 0 auto;
  padding: 0.2rem;
  font-size: 1rem;
}

.tile-group {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;

  .card {
    position: relative;
    height: 10rem;
    width: 10rem;
    margin: 1rem;
    box-shadow: 0px 0px 6px #d9d9d9;
    padding: 0.8rem;
    color: #3a3a3a;

    div {
      background: #e0e0e0;
      border-radius: 0.5rem;
    }
  }

  .card:hover {
    background-color: #03a9f4;
    color: #3a3a3a;

    i {
      color: #3a3a3a;
    }
  }
  a {
    text-decoration: none;
  }

  .card-title {
    padding: 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    height: 2rem;
    margin: 0 auto;

    i {
      color: #3a3a3a;
      font-size: 1.5rem;
    }
  }

  .card-body {
    padding: 0.5rem;
    margin: auto;
    height: 6.5rem;
    border-radius: 0.3rem;
    font-size: 1rem;
    font-weight: bold;
  }
}
