@import "../../themes/generated/variables.base.scss";

.user-info, .signIn-con {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 38px;

  .dx-toolbar-menu-section & {
    padding: 10px 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  .image-container {
    overflow: hidden;
    border-radius: 50%;
    min-height: 30px;
    min-width: 30px;
    margin: 0 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 3px rgba(0,0,0,0.15);

    .user-image {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 28px;

      i {
        flex: auto;
        margin: auto;
        color: #9b9b9b;
        font-size: 1.6rem;
        text-shadow: 0 1px 3px rgba(0,0,0,0.15);
      }
    }
  }

  .user-name {
    font-size: 14px;
    color: $base-text-color;
    margin: 0 9px;
  }
}

.user-panel {
  flex: auto;
  margin: 0 auto;
  width: auto;
  text-align: end;

  .dx-list-item .dx-icon {
    vertical-align: middle;
    color: $base-text-color;
    margin-right: 16px;
  }
  .dx-rtl .dx-list-item .dx-icon {
    margin-right: 0;
    margin-left: 16px;
  }
}

.signIn-panel {
  flex: 1;
  margin: 0 auto;  
  width: 50%;

  button {
    background: #03a9f4;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }
  button:hover {
    background: #0078af;
  }
}

.dx-context-menu.user-menu.dx-menu-base {
  &.dx-rtl {
    .dx-submenu .dx-menu-items-container .dx-icon {
      margin-left: 16px;
    }
  }
  .dx-submenu .dx-menu-items-container .dx-icon {
    margin-right: 16px;
  }
  .dx-menu-item .dx-menu-item-content {
      padding: 3px 15px 4px;
  }
}

.dx-theme-generic .user-menu .dx-menu-item-content .dx-menu-item-text {
  padding-left: 4px;
  padding-right: 4px;
}
