@import "./themes/generated/variables.base.scss";

.app {
  background-color: darken($base-bg, 5.00);
  display: flex;
  height: 100%;
  width: 100%;
}

.app .content {
  line-height: 1.5;

  h2 {
    font-size: 30px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.app .content-block {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
}

.screen-x-small .content-block {
  margin-left: 20px;
  margin-right: 20px;
}

.responsive-paddings {
  padding: 20px;

  .screen-large & {
    padding: 40px;
  }
}

.dx-card.wide-card {
  border-radius: 0;
  margin-left: 0;
  margin-right: 0;
  border-right: 0;
  border-left: 0;
}

.with-footer > .dx-scrollable-wrapper >
.dx-scrollable-container > .dx-scrollable-content {
  height: 100%;

  & > .dx-scrollview-content {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }
}

$side-panel-min-width: 60px;

html, body {
  margin: 0px;
  min-height: 100%;
  height: 100%;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.signIn-loginCon {
  flex: auto;
  text-align: center;
  margin: 0 auto;
  width: 50%;
  padding: 5px;

  button {
    width: 100%;
    background: #03a9f4;
    box-shadow: 0px 0px 6px #979797;
  }

  button:hover {
    background: #0187c5;
  }
}
