#gridContainer {
  height: 50vh;
}

.patientDetailsFooter {
  display: flex;
  margin: 10px;
  

  .patient,
  .address {
    flex: 1 1;
    margin: 0 auto;
    width: auto;

    h6 {
      border-bottom: 2px solid #d9d9d9;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    div {
      display: flex;
      width: 100%;
      font-size: 0.9rem;

      p {
        flex: auto;
        width: 50%;
        margin: 0 auto;
        padding: 2px;
        font-size: 0.9rem;
      }
    }
  }

  .notes {
    flex: 1 1;
    margin: 0 auto;
    width: auto;

    h6 {
      border-bottom: 2px solid #d9d9d9;
    }

    p {
      flex: auto;
      margin: 0 auto;
      padding: 2px;
      font-size: 0.9rem;
    }
  }
}
